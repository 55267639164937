import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.set";
import _toConsumableArray from "/root/workspace/crm_web_5uHk/node_modules/_@babel_runtime-corejs2@7.25.4@@babel/runtime-corejs2/helpers/esm/toConsumableArray.js";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.number.constructor";
import "core-js/modules/es6.regexp.replace";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ClassManagementIndex, editClassInfo, delClassInfo, getClassInfo, getCollegeList, getMajorList, getMainTeacherList } from '@/api/ceshi_xiugai/product';
import moment from 'moment';
import Head from '@/components/head/index';
import mailListEs from '@/components/StaffSelect/mailListRadioEs1';
export default {
  name: 'ClassManagement',
  data: function data() {
    return {
      // 系统教程
      tab: 0,
      course: this.$route.query.course,
      prohibit: false,
      mailListVisibleEs: false,
      radioEs: false,
      menuId: localStorage.getItem('menuId'),
      // 系统教程
      title: '班级管理',
      query: {
        page: 1,
        pagesize: 10,
        total: 0 //总条数，不是总页数
      },
      text: [],
      condition: [],
      staffResultEs: [],
      colleges: [],
      collegesErr: [],
      majorList: [],
      majorLists: [],
      headmasterArr: [],
      chargeList: [{
        value: 2,
        label: '全部'
      }, {
        value: 1,
        label: '参与'
      }, {
        value: 0,
        label: '不参与'
      }],
      chargeListSE: [{
        id: 1,
        val: '是'
      }, {
        id: 0,
        val: '否'
      }],
      YearArr: [{
        value: 2019,
        label: '2019'
      }, {
        value: 2020,
        label: '2020'
      }, {
        value: 2021,
        label: '2021'
      }, {
        value: 2022,
        label: '2022'
      }, {
        value: 2023,
        label: '2023'
      }, {
        value: 2024,
        label: '2024'
      }, {
        value: 2025,
        label: '2025'
      }],
      scree: {
        page: 1,
        pagesize: 15,
        key_word: '',
        id: '',
        pageList: 1,
        pagesizeList: 15,
        key_wordList: '',
        courtyard: '',
        major: '',
        charge: 2,
        year: '',
        class: '',
        headmaster: ''
      },
      MajorShow: true,
      FMajorShow: true,
      currentPage4: 1,
      ClassSearch: '',
      listLoading: true,
      dialogFormVisible: false,
      tableData: [],
      textMap: {
        update: '编辑班级',
        create: '新建班级'
      },
      adminName: '',
      dialogStatus: '',
      timeShow: false,
      formLabelWidth: '120px',
      FormJson: {
        Cid: '',
        name: '',
        colleges: '',
        major: '',
        charge: 10,
        headmaster: '',
        year: '',
        numbermin: '',
        numbermax: '',
        radios: 2,
        OpeningTime: []
      },
      rules: {
        name: [{
          required: true,
          message: '请输入班级名称',
          trigger: 'blur'
        }],
        colleges: [{
          required: true,
          message: '请选择院校',
          trigger: 'change'
        }],
        charge: [{
          required: true,
          message: '请选择活动区域',
          trigger: 'change'
        }],
        major: [{
          required: true,
          message: '请选择所属专业',
          trigger: 'change'
        }],
        headmaster: [{
          required: true,
          message: '请选择班级班主任',
          trigger: 'change'
        }]
      },
      details: {
        //单条数据详情
        title: '',
        sort: '',
        courtyard: '',
        major: '',
        year: '',
        quarter: '',
        address: ''
      },
      courtyard1: [],
      major1: [],
      year1: [],
      quarter1: [],
      address1: [],
      tempId: [],
      value1: '',
      detainfo: 0,
      collegesArr: {},
      majorListsArr: {},
      switch: 0
    };
  },
  mounted: function mounted() {
    if (this.course != 1) {
      this.getList(this.query.page, this.query.pagesize);
      this.CourtyardFocus();
    } else {}
  },
  components: {
    Head: Head,
    mailListEs: mailListEs
  },
  methods: {
    //时间
    screening: function screening(item) {
      this.starttime = item[0];
      this.endtime = item[1];
    },
    staffSelectConfirmEs: function staffSelectConfirmEs(resultNameArr, result) {
      this.adminName = String(resultNameArr);
      this.FormJson.headmaster = String(result);
      this.text = result;
      this.disabledShow = false;
    },
    mailList: function mailList() {
      this.radioEs = true;
      this.mailListVisibleEs = true;
      this.disabledShow = true;
    },
    cancel: function cancel() {
      this.disabledShow = false;
    },
    prevent: function prevent(e) {
      var keynum = window.event ? e.keyCode : e.which; //获取键盘码
      this.$message.closeAll();
      // 验证是否是纯数字
      var isNumber = /^\d*$/.test(e.target.value);
      // 过滤非数字
      e.target.value = e.target.value.replace(/\D/g, '');
      if (keynum == 189 || keynum == 190 || keynum == 109 || keynum == 110) {
        this.$message.warning('禁止输入小数以及负数');
        // e.target.value = ''
      } else if (!isNumber || e.target.value <= 0 || e.target.value > 1000) {
        this.$message.warning('请填写<=1000的正整数');
        // e.target.value = ''
      } else if (this.FormJson.numbermax != '') {
        if (Number(this.FormJson.numbermax) <= Number(this.FormJson.numbermin)) {
          this.$message.warning('请填写大于前面的人数');
        }
      }
    },
    radiosChaio: function radiosChaio(val) {
      this.timeShow = val == 1 ? true : false;
      this.FormJson.OpeningTime = this.timeShow ? [{
        time: ''
      }] : [];
    },
    classification: function classification() {
      var json = {
        time: ''
      };
      this.FormJson.OpeningTime.push(json);
    },
    DeleteCondition: function DeleteCondition(index) {
      this.FormJson.OpeningTime.splice(index, 1);
    },
    CourtyardFocus: function CourtyardFocus() {
      var _this2 = this;
      getCollegeList({
        page: this.scree.page,
        pagesize: this.scree.pagesize
      }).then(function (res) {
        res.data.school_list.forEach(function (item) {
          _this2.colleges.push(item);
          _this2.collegesErr.push(item);
        });
      });
    },
    CourtyardName: function CourtyardName() {
      var _this3 = this;
      if (this.scree.pagesize != 0) {
        this.scree.page++;
        getCollegeList({
          key_word: this.scree.key_word,
          page: this.scree.page,
          pagesize: this.scree.pagesize
        }).then(function (res) {
          res.data.school_list.forEach(function (item) {
            _this3.colleges.push(item);
            _this3.collegesErr.push(item);
          });
          if (JSON.stringify(_this3.collegesArr) != '{}') {
            _this3.collegesErr.unshift(_this3.collegesArr);
            _this3.collegesErr = _this3.dedupe(_this3.collegesErr);
          }
        });
      }
    },
    CourtyardMethod: function CourtyardMethod(val) {
      var _this4 = this;
      this.scree.key_word = val;
      this.scree.page = 1;
      getCollegeList({
        key_word: this.scree.key_word,
        page: this.scree.page,
        pagesize: this.scree.pagesize
      }).then(function (res) {
        var arr = [];
        res.data.school_list.forEach(function (item) {
          arr.push(item);
        });
        _this4.colleges = res.data.school_list;
        _this4.collegesErr = _this4.dedupe(arr);
        if (JSON.stringify(_this4.collegesArr) != '{}') {
          _this4.collegesErr.unshift(_this4.collegesArr);
        }
      });
    },
    CourtyardFocuse: function CourtyardFocuse(id) {
      var _this5 = this;
      this.scree.key_word = '';
      this.scree.page = 1;
      getCollegeList({
        key_word: this.scree.key_word,
        page: this.scree.page,
        pagesize: this.scree.pagesize
      }).then(function (res) {
        var arr = [];
        res.data.school_list.forEach(function (item) {
          arr.push(item);
        });
        _this5.colleges = arr;
        _this5.collegesErr = _this5.dedupe(arr);
        if (JSON.stringify(_this5.collegesArr) != '{}') {
          _this5.collegesErr.unshift(_this5.collegesArr);
        }
      });
    },
    CourtyardChange: function CourtyardChange(id) {
      var _this6 = this;
      if (id != '') {
        this.scree.id = id;
        getMajorList({
          school_id: id,
          page: this.scree.pageList,
          pagesize: this.scree.pagesizeList,
          key_word: this.scree.key_wordList
        }).then(function (res) {
          _this6.MajorShow = false;
          _this6.majorList = [];
          _this6.scree.major = '';
          _this6.majorList = res.data.major_list;
        });
      }
    },
    CourtyardChanges: function CourtyardChanges(id) {
      var _this7 = this;
      if (id != '') {
        this.FormJson.Cid = id;
        this.scree.id = id;
        this.scree.pageList = 1;
        this.scree.key_wordList = '';
        getMajorList({
          school_id: id,
          page: this.scree.pageList,
          pagesize: this.scree.pagesizeList,
          key_word: this.scree.key_wordList
        }).then(function (res) {
          _this7.FMajorShow = false;
          _this7.switch = 1;
          _this7.majorLists = res.data.major_list;
          if (_this7.majorLists.length > 0) {
            _this7.FormJson.major = _this7.majorLists[0].id;
          }
          if (_this7.FormJson.major) {
            _this7.FormJson.major = '';
          }
          _this7.$nextTick(function () {
            _this7.$refs['FormJson'].clearValidate('major'); //清楚当前select的校验规则
          });
        });
      }
    },
    dedupe: function dedupe(array) {
      //json去重数组方法
      var d = [];
      var hash = {};
      d = array.reduce(function (item, next) {
        hash[next.id] ? '' : hash[next.id] = true && item.push(next);
        return item;
      }, []);
      return d;
    },
    MajorFocus: function MajorFocus() {
      var _this8 = this;
      this.scree.pageList = 1;
      getMajorList({
        school_id: this.scree.id,
        page: this.scree.pageList,
        pagesize: this.scree.pagesizeList
      }).then(function (res) {
        _this8.majorLists = res.data.major_list;
      });
    },
    MajorFocuss: function MajorFocuss() {
      var _this9 = this;
      this.scree.pageList = 1;
      getMajorList({
        school_id: this.FormJson.Cid,
        page: this.scree.pageList,
        pagesize: this.scree.pagesizeList
      }).then(function (res) {
        res.data.major_list.forEach(function (item) {
          _this9.majorLists.push(item);
        });
        if (_this9.switch != 1) {
          if (JSON.stringify(_this9.majorListsArr) != '{}') {
            _this9.majorLists.unshift(_this9.majorListsArr);
          }
        }
        _this9.majorLists = _this9.dedupe(_this9.majorLists);
      });
    },
    MajorName: function MajorName() {
      var _this10 = this;
      if (this.scree.pageList != 0) {
        this.scree.pageList++;
        getMajorList({
          school_id: this.scree.id,
          key_word: this.scree.key_wordList,
          page: this.scree.pageList,
          pagesize: this.scree.pagesizeList
        }).then(function (res) {
          res.data.major_list.forEach(function (item) {
            _this10.majorList.push(item);
          });
        });
      }
    },
    MajorNames: function MajorNames() {
      var _this11 = this;
      this.scree.pageList++;
      getMajorList({
        school_id: this.scree.id,
        key_word: this.scree.key_wordList,
        page: this.scree.pageList,
        pagesize: this.scree.pagesizeList
      }).then(function (res) {
        res.data.major_list.forEach(function (item) {
          _this11.majorList.push(item);
          _this11.majorLists.push(item);
        });
        _this11.majorLists = _this11.dedupe(_this11.majorLists);
      });
    },
    MajorMethod: function MajorMethod(val) {
      var _this12 = this;
      this.scree.key_wordList = val;
      this.scree.pageList = 1;
      getMajorList({
        school_id: this.scree.id,
        key_word: this.scree.key_wordList,
        page: this.scree.pageList,
        pagesize: this.scree.pagesizeList
      }).then(function (res) {
        _this12.majorList = res.data.major_list;
      });
    },
    MajorMethods: function MajorMethods(val) {
      var _this13 = this;
      this.scree.key_wordList = val;
      this.scree.pageList = 1;
      getMajorList({
        school_id: this.FormJson.Cid,
        key_word: this.scree.key_wordList,
        page: this.scree.pageList,
        pagesize: this.scree.pagesizeList
      }).then(function (res) {
        _this13.majorLists = _this13.dedupe(res.data.major_list);
        if (JSON.stringify(_this13.majorLists) != '{}') {
          _this13.majorLists.unshift(_this13.majorLists);
          _this13.majorLists = _this13.dedupe(_this13.majorLists);
        }
      });
    },
    CourtyardClear: function CourtyardClear() {
      this.MajorShow = true;
      this.scree.major = '';
      this.scree.id = '';
    },
    QueryClick: function QueryClick() {
      this.query.page = 1;
      this.getList(this.query.page, this.query.pagesize);
    },
    getList: function getList(page, pagesize) {
      var _this14 = this;
      //班级列表
      this.listLoading = true;
      ClassManagementIndex({
        page: page,
        pagesize: pagesize,
        colleges_id: this.scree.courtyard,
        major_id: this.scree.major,
        is_charges: this.scree.charge,
        year: this.scree.year,
        title: this.scree.class,
        main_teacher: this.scree.headmaster
      }).then(function (respomse) {
        var data = respomse.data;
        data.data.map(function (item) {
          if (item.begin_class_time.length > 0) {
            item.begin_class_time = item.begin_class_time.map(function (event) {
              event = event.substr(0, 10);
              return event;
            });
          }
        });
        _this14.tableData = data.data;
        _this14.query.total = data.total;
        _this14.listLoading = false;
      }).catch(function () {
        _this14.tableData = [];
        _this14.listLoading = false;
      });
    },
    ClassSearchInput: function ClassSearchInput() {
      this.ClassSearch;
      if (this.ClassSearch == '') {
        // this.$message({
        //     message: '请输入班级名称',
        //     type: 'warning'
        // });
        this.getList(this.query.page, this.query.pagesize);
      } else {
        this.query.page = 1;
        this.getList(this.query.page, this.query.pagesize, this.ClassSearch);
      }
    },
    handleSizeChange: function handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.getList(this.query.page, val);
      this.query.pagesize = val;
    },
    handleCurrentChange: function handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.getList(val, this.query.pagesize);
      this.query.page = val;
    },
    resetTemps: function resetTemps() {
      //清空班级弹窗
      this.FormJson = {
        Cid: '',
        name: '',
        colleges: '',
        major: '',
        charge: 0,
        headmaster: '',
        year: '',
        numbermin: '',
        numbermax: '',
        radios: 2,
        OpeningTime: []
      };
      this.adminName = '';
      this.scree.page = 1;
      this.scree.pagesize = 15;
      this.scree.pageList = 1;
      this.scree.pagesizeList = 15;
      this.FMajorShow = true;
      this.timeShow = false;
      this.detainfo = 0;
      this.switch = 0;
      this.collegesArr = {};
      this.majorListsArr = {};
      // 
    },
    Announcement: function Announcement(FormJson) {
      var _this15 = this;
      //新增班级
      this.$refs[FormJson].validate(function (valid) {
        if (valid) {
          var _this = _this15.FormJson;
          var arr = [];
          _this.OpeningTime.filter(function (item) {
            if (item.time == '' || item.time == null) {
              arr.push(item);
            } else {
              arr = [];
            }
          });
          // console.log(arr,_this.OpeningTime);
          if (_this.numbermax != '' && _this.numbermin != '') {
            if (Number(_this.numbermax) <= Number(_this.numbermin)) {
              _this15.$message({
                message: '人数条件需为前值小于后值的正整数',
                type: 'warning'
              });
            } else {
              _this15.Submit();
            }
          } else if (_this.radios == 1) {
            if (arr.length > 0 || _this.OpeningTime.length == 0) {
              _this15.$message({
                message: '请完善开班时间~',
                type: 'warning'
              });
            } else {
              _this15.Submit();
            }
          } else {
            _this15.Submit();
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    Submit: function Submit() {
      var _this16 = this;
      var _this = this.FormJson;
      _this.OpeningTime.map(function (item) {
        item.time = item.time.toString().substr(0, 10);
      });
      editClassInfo({
        title: _this.name,
        colleges_id: _this.colleges,
        major_id: _this.major,
        is_charges: _this.charge,
        main_teacher_id: _this.headmaster,
        class_number_min: Number(_this.numbermin),
        class_number_max: Number(_this.numbermax),
        begin_class_time: _this.OpeningTime,
        year: _this.year
      }).then(function (res) {
        _this16.getList(_this16.query.page, _this16.query.pagesize);
        _this16.$notify({
          title: '成功',
          message: '新建成功',
          type: 'success',
          duration: 2000
        });
        _this16.resetForm('FormJson');
        _this16.timeShow = false;
        _this16.dialogFormVisible = false;
      });
    },
    infodata: function infodata() {
      var _this17 = this;
      //获取班级详情
      if (this.tempId.length > 0) {
        if (this.tempId.length < 2) {
          getClassInfo({
            id: this.tempId.toString()
          }).then(function (res) {
            var data = res.data;
            var arr = [];
            _this17.text = [];
            data.begin_class_time.forEach(function (item) {
              arr.push({
                time: String(moment(item).unix()) + '000'
              });
            });
            _this17.text.push(data.main_teacher_id != '' ? data.main_teacher_id : '');
            _this17.FormJson = {
              Cid: data.id,
              name: data.title,
              colleges: data.colleges_id,
              major: data.major_id,
              charge: data.is_charges,
              headmaster: data.main_teacher_id != '' ? data.main_teacher_id : '',
              year: data.year,
              numbermin: data.class_number_min,
              numbermax: data.class_number_max,
              radios: data.begin_class_time.length > 0 ? 1 : 2,
              OpeningTime: arr
            };
            _this17.majorLists = [];
            // major_id/major_name     colleges_id/colleges_name

            _this17.scree.id = data.colleges_id;
            _this17.adminName = data.main_teacher_name;
            _this17.FormJson.Cid = data.colleges_id;
            _this17.timeShow = _this17.FormJson.radios == 1 ? true : false;
            _this17.FMajorShow = _this17.FormJson.major != '' ? false : true;
            _this17.CourtyardFocus();
            _this17.MajorFocuss();
            _this17.collegesArr = {
              id: data.colleges_id,
              title: data.colleges_name
            };
            _this17.collegesErr.unshift(_this17.collegesArr);
            _this17.majorListsArr = {
              id: data.major_id,
              title: data.major_name
            };
            _this17.detainfo = 1;
            _this17.majorLists.unshift(_this17.majorListsArr);
          });
        } else {
          this.dialogFormVisible = false;
          this.$message({
            type: 'info',
            message: '请选择一个班级'
          });
        }
      } else {
        this.dialogFormVisible = false;
        this.$message({
          type: 'info',
          message: '请选择班级'
        });
      }
    },
    EditorialAnnouncement: function EditorialAnnouncement(FormJson) {
      var _this18 = this;
      //编辑班级
      this.$refs[FormJson].validate(function (valid) {
        if (valid) {
          var _this = _this18.FormJson;
          var arr = [];
          _this.OpeningTime.filter(function (item) {
            if (item.time == '' || item.time == null) {
              arr.push(item);
            } else {
              arr = [];
            }
          });
          if (_this.numbermax != '' && _this.numbermin == '' || _this.numbermax == '' && _this.numbermin != '') {
            _this18.$message({
              message: '人数条件需为前值小于后值的正整数1',
              type: 'warning'
            });
            return false;
          } else if (Number(_this.numbermin) >= Number(_this.numbermax) && _this.numbermax != '' && _this.numbermin != '') {
            _this18.$message({
              message: '人数条件需为前值小于后值的正整数2',
              type: 'warning'
            });
            return false;
          } else if (_this.radios == 1) {
            if (arr.length > 0 || _this.OpeningTime.length == 0) {
              _this18.$message({
                message: '请完善开班时间~',
                type: 'warning'
              });
              return false;
            } else {
              _this18.SubmitS();
            }
          } else {
            _this18.SubmitS();
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    SubmitS: function SubmitS() {
      var _this19 = this;
      var _this = this.FormJson;
      _this.OpeningTime.map(function (item) {
        item.time = item.time.toString().substr(0, 10);
      });
      editClassInfo({
        id: this.tempId.toString(),
        title: _this.name,
        colleges_id: _this.colleges,
        major_id: _this.major,
        is_charges: _this.charge,
        main_teacher_id: _this.headmaster,
        class_number_min: Number(_this.numbermin),
        class_number_max: Number(_this.numbermax),
        begin_class_time: _this.OpeningTime,
        year: _this.year
      }).then(function (res) {
        _this19.getList(_this19.query.page, _this19.query.pagesize);
        _this19.$notify({
          title: '成功',
          message: '编辑成功',
          type: 'success',
          duration: 2000
        });
        _this19.resetForm('FormJson');
        _this19.timeShow = false;
        _this19.dialogFormVisible = false;
      });
    },
    deleteBtn: function deleteBtn() {
      var _this20 = this;
      //删除班级
      if (this.tempId.length > 0) {
        this.$confirm('此操作将永久删除该班级, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          var data = {
            class_id_list: String(_this20.tempId)
          };
          delClassInfo(data).then(function (res) {
            _this20.getList(_this20.query.page, _this20.query.pagesize);
            _this20.$notify({
              title: '成功',
              message: '删除成功',
              type: 'success',
              duration: 2000
            });
          });
        }).catch(function () {
          _this20.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      } else {
        this.$message({
          type: 'info',
          message: '请选择班级'
        });
      }
    },
    handleSelectionChange: function handleSelectionChange(val) {
      //获取选中id
      var dataId = [];
      val.forEach(function (item) {
        dataId.push(item.id);
      });
      this.tempId = _toConsumableArray(new Set(dataId));
      // if (val.length > 1) {
      //     this.$refs.tb.clearSelection();
      //     this.$refs.tb.toggleRowSelection(val.pop());
      // }
    },
    resetForm: function resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
};